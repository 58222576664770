import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "create-a-user"
    }}>{`Create a User`}</h1>
    <hr></hr>
    <p>{`To create a new user for the Content Mangement System, follow these step-by-step instructions. `}<em parentName="p">{`Some of the fields in this section do not have an relation to the mobile app or the Content Management System. Only fields that do have an affect, will be detailed below. Ones not listed can be ignored.`}</em>{`:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Mangement System and click on Users from the left side menu options. Within the Users Manger, you will see all the user accounts you have created and have access to in alphabetical order. The account's login name, user's name, user type, last login, the channels they have access to, how many items they own and the option to delete are displayed in this section. `}</li>
      <li parentName="ol">{`Click on 'Add new User'.`}</li>
      <li parentName="ol">{`Provide a Username. This will be used to log into the Content Management System.`}</li>
      <li parentName="ol">{`Enter in the user's full name.`}</li>
      <li parentName="ol">{`Enter in a password. When the user logs in, they are able to change this as needed.`}</li>
      <li parentName="ol">{`Re-type the password. It must match what was entered in the 'Password' field. `}</li>
      <li parentName="ol">{`Set the Time Zone for the user. `}</li>
      <li parentName="ol">{`Set the default language for content, channels, and apps created by this user. `}</li>
      <li parentName="ol">{`Enter in an email address for this user account. `}<em parentName="li">{`This email address is used for the activity report that is sent out monthly.`}</em></li>
      <li parentName="ol">{`If you want this user to be allowed to create content items that can be used within your mobile app, enable 'Mobile Publishing'. If you are assigning this permission, you should also enable the 'Mobile' checkbox under the Contents Defaults section.`}</li>
      <li parentName="ol">{`If you want this user to change or update the settings of Channels granted to them, enable 'Channel Settings'.`}</li>
      <li parentName="ol">{`If you want the user to be asked to create a schedule for content item, enable 'Schedule Alert and Times Items'. For most users, you will not need to assign this permission. `}</li>
      <li parentName="ol">{`If you want this user to access and modify content that has been created by the parent user account, enable 'Access Parent Content'. `}</li>
      <li parentName="ol">{`If you want this user's content to be auto-published for your app, enable 'Mobile' under the Content Defaults section. It is reccomended that you enable this if the user will be creating and maintaining content for your app. `}</li>
      <li parentName="ol">{`Under Content Types, select which types this user can create. `}</li>
      <li parentName="ol">{`Under Mobile Channels, select which Channels this user can access and edit.`}</li>
      <li parentName="ol">{`Under Mobile Apps, select which Mobile App(s) this user can access and edit. `}</li>
    </ol>
    <p>{`Related Training Video:
`}<a parentName="p" {...{
        "href": "https://appdocs.sol.us/trainings-creating-users"
      }}>{`Creating Users Training Video`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      